@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$smartdp-search-marketing-color: $black !default;

.sdp-home {
	&__hero {
		position: relative;
		height: 220px;

		.background-progressive-image {
			height: 160px;
		}

		@include gridle_state(md) {
			height: calc(100vh - 70px);

			&-content {
				@include container;

				position: absolute;
				left: 0;
				right: 0;
				height: 100%;
				top: 0;
				bottom: 0;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}

	&__marketing {
		color: white;
		text-shadow: 0 4px 7px rgba(0, 0, 0, 0.5);
		line-height: 1;
		flex: 1 1 0%;

		@include gridle_state(md) {
			display: flex;
			width: 100%;
			justify-content: center;
		}
	}

	&__marketing-container {
		position: absolute;
		height: 130px;
		bottom: 0;
		left: 0;
		right: 0;
		background-image: linear-gradient(
			to bottom,
			rgba(51, 51, 51, 0),
			$smartdp-search-marketing-color 60%,
			$smartdp-search-marketing-color 100%
		);
		padding: 0 15px 20px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		@include gridle_state(md) {
			background: none;
			height: auto;
			bottom: initial;
			position: initial;
			margin-right: 60px;
			padding: 0;
			min-width: 500px;
		}
	}

	&__form {
		padding: 0 15px 20px;
		background: $color-primary;

		@include gridle_state(md) {
			position: relative;
			background: none;
			padding: 0;
			width: 372px;

			.auth__main {
				padding: 30px;
			}
		}

		.auth {
			max-width: initial;
			color: $auth-form-text-color-static;

			&__intro {
				.icon--cross-dark {
					background-image: url("/static/icons/icon-cross.svg");
				}
			}

			&__resetpassword {
				&__action {
					.popover {
						bottom: 40px;
					}
				}

				.auth__main {
					border-radius: 5px;
				}
			}

			&__accept-cgv {
				a {
					color: $auth-form-text-color-static;
				}

				.checkbox--checked {
					path,
					g {
						fill: $auth-form-text-color-static;
					}
				}
			}

			&__forgotten-password {
				min-height: 40px;
				justify-content: flex-end;
				align-items: flex-start;

				.relative-link:hover {
					text-decoration: underline $auth-form-text-color-static;
				}

				.relative-link__text {
					color: $auth-form-text-color-static;
					text-align: right;
					font-size: 1.1rem;
				}
			}

			&__separator::before,
			&__separator::after {
				height: 1px;
				background: $auth-form-text-color-static;
				opacity: 0.2;
			}

			&__terms {
				color: $auth-form-text-color-static;

				.relative-link {
					color: $auth-form-text-color-static;
				}
			}

			&__main {
				border-radius: 5px;
				background: $auth-form-background-static;
				padding: 30px;

				.control-group {
					&__input {
						background: white;
					}

					* {
						color: $black;
						fill: $black;
					}

					.icon-eye-off path {
						stroke: $auth-form-text-color-static;
					}
				}
			}

			&__loader {
				background: $auth-form-background-static;
			}

			&__switch {
				&__item {
					color: $auth-form-text-color-static;
				}
			}

			&__credit {
				&-banner {
					color: $auth-form-text-color-static;

					.amount {
						color: $auth-form-text-color-static;
					}
				}
			}
		}
	}

	&__blocks {
		&-item {
			padding: 30px 15px;

			&:nth-child(2n + 1) {
				background: $grey-light;
			}

			> * {
				@include container;
			}

			.pp-carousel-block {
				padding: 0;

				.product-deal,
				.product-with-translucent-deal {
					pointer-events: none;

					&__info--left {
						width: 100%;
					}

					&__info--right {
						display: none;
					}
				}

				&__cta {
					display: none;
				}
			}

			@include gridle_state(md) {
				padding-top: 40px;
				padding-bottom: 40px;
			}
		}
	}

	.product-list-block {
		&__view-all-offers-link,
		&__view-all-offers-button,
		.carousel-dots {
			display: none !important; // hide carousel dots
		}

		&__products {
			pointer-events: none;
		}
	}
}
