@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.home {
	&__rtb {
		border-top: 1px solid $grey-medium;

		.reason-to-book-block {
			padding-top: 32px;
			padding-bottom: 32px;

			@include gridle_state(md) {
				max-width: 1024px;
				margin: 0 auto;
				padding: 40px 0;
			}
		}
	}
}
