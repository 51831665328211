@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
%grid-2-columns {
	display: flex;
	flex-direction: column;
	justify-content: center;

	@include gridle_state(md) {
		display: grid;
		grid-template-columns: repeat(2, calc(50% - 20px));
		grid-gap: 32px;
	}
}

%grid-3-columns {
	display: flex;
	flex-direction: column;
	justify-content: center;

	@include gridle_state(md) {
		display: grid;
		grid-template-columns: repeat(3, 325px);
		grid-column-gap: 24px;
		grid-row-gap: 32px;
	}
}

.product-list-block {
	&__title {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 24px;
	}

	&__view-all-offers {
		&-link {
			.relative-link {
				@extend %link;
			}
		}

		&-button {
			margin-top: 24px;

			svg {
				margin-left: 8px;
			}
		}
	}

	&__products {
		@extend %grid-3-columns;
	}

	.products-carousel {
		padding-left: 0;
		padding-right: 0;

		&__item {
			min-height: 310px;
		}
	}

	.product,
	.product-with-translucent-deal {
		&__info {
			&--left {
				width: 100%;
			}

			&--right {
				display: none;
			}
		}
	}

	.product-card {
		&__action {
			display: none;
		}
	}

	.product-list-block__products,
	.products-carousel {
		.product-card {
			&__picture {
				@include gridle_state(md) {
					height: 185px;

					.images-carousel-pp {
						&__image {
							height: 185px;
						}
					}
				}
			}
		}
	}

	// style pour réduire les products cards avec design d'aujourd'hui
	// A supprimer ou corriger une fois que l'on appliquera le nouveau design des products cards
	.product-list-block__products,
	.products-carousel {
		.save-up-to__prefix,
		.from__value .amount__prefix,
		.product-time {
			@include gridle_state(sm) {
				font-size: 1.2rem;
			}
		}

		.deal__exclusive {
			@include gridle_state(sm) {
				font-size: 1.54rem;
				letter-spacing: 0.2rem;
			}
		}

		.deal__timer,
		.deal__price-type {
			@include gridle_state(sm) {
				min-width: auto; // eviter que le temps restant soit plus petit que le prix
			}
		}

		.product-with-translucent-deal,
		.product {
			&__name {
				@include gridle_state(sm) {
					font-size: 1.2rem;
				}
			}

			&__destination {
				@include gridle_state(sm) {
					font-size: 1.9rem;
				}
			}

			&-time {
				.icon {
					@include gridle_state(sm) {
						height: 15px;
						width: 15px;
						margin-right: 5px;
					}
				}
			}

			&__main {
				@include gridle_grid(24);
			}

			&__info--left {
				width: 100%;
			}

			&__info--right {
				display: none;
			}

			&__action {
				display: none;
			}

			&-deal {
				&__info {
					font-size: 1.2rem;
				}
			}
		}

		.product {
			&__picture {
				@include gridle_state(md) {
					height: 157px;

					.images-carousel-pp {
						&__image {
							height: 157px;
						}
					}
				}
			}

			.product-destination-title {
				font-size: 1.8rem;
			}

			.product-name-with-category {
				font-size: 1.4rem;
			}
		}

		.product-with-translucent-deal {
			&__picture {
				@include gridle_state(md) {
					height: 212px;

					.images-carousel-pp {
						&__image {
							height: 212px;
						}
					}
				}
			}

			.product-destination-title {
				font-size: 1.8rem;
			}

			.product-name-with-category {
				font-size: 1.4rem;
			}
		}

		.category {
			.icon {
				@include gridle_state(sm) {
					height: 13px;
					width: 13px;
				}
			}
		}

		.save-up-to__value {
			@include gridle_state(sm) {
				font-size: 2.4rem;
			}
		}

		.from__value .amount__value {
			font-size: 2rem;
		}

		.colored-product-badge {
			font-size: 1.4rem;
		}

		.product-with-translucent-deal,
		.product {
			&__footer {
				> .relative-link {
					padding: 12px;
				}
			}

			&__info--left {
				width: 100%;
			}

			&__info--right {
				display: none;
			}
		}
	}

	// END style pour réduire les products cards avec design d'aujourd'hui
	// A supprimer ou corriger une fois que l'on appliquera le nouveau design des products cards
}
